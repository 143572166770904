<template>
  <div class="Connect">
    <div class="title">{{ $t("userTab.address") }}</div>
    <div class="block">
      <div class="item" v-for="(val, key) in list" :key="key">
        <template v-if="val.edit">
          <AddressFrom
            :detail="detail"
            :isEdit="isEdit"
            @done="getList"
            @cancel="cancel"
          ></AddressFrom>
        </template>
        <template v-else>
          <div class="information">
            <div class="col">
              <span class="key">{{ $t("address.man") }}</span>
              <span class="val">{{ val.man }}</span>
            </div>
            <div class="col">
              <span class="key">{{ $t("address.phone") }}</span>
              <span class="val">{{ val.phone }}</span>
            </div>
            <div class="col">
              <span class="key">{{ $t("address.address") }}</span>
              <span class="val">{{ val.area }} - {{ val.address }}</span>
            </div>
          </div>
          <div class="button">
            <div
              class="edit"
              @click="
                cancel();
                list[key].edit = true;
                isEdit = true;
                detail = val;
              "
            >
              {{ $t("address.edit") }}
            </div>
            <div class="del" @click="del(val.addressId)">{{ $t("address.delete") }}</div>
          </div>
        </template>
      </div>
      <div
        class="newAddress"
        @click="
          cancel();
          newAddress();
        "
      >
        {{ $t("address.newAddress") }}
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api/request.js";
import AddressFrom from "./form.vue";
export default {
  name: "Connect",
  components: {
    AddressFrom,
  },
  data() {
    return {
      detail: {},
      isEdit: false,
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$loading.start();
      Api.Address.list({}).then((res) => {
        if (res.data.data) {
          res.data.data.map((item) => {
            item.edit = false;
          });
        }
        this.list = res.data.data || [];
        this.$loading.done();
      });
    },
    cancel() {
      this.list.map((item, key) => {
        if (!item.addressId) {
          this.list.splice(key, 1);
        }
        item.edit = false;
      });
    },
    newAddress() {
      this.list.push({
        edit: true,
      });
    },
    del(id) {
      this.$dialog.confirm(this.$t("address.deleteTip")).then((res) => {
        this.$dialog.loading();
        Api.Address.del({ addressId: id }).then((res) => {
          if (res.data.status == "SUCCESSS") {
            this.getList();
          }
          this.$dialog.close();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 65px;
}
.block {
  padding: 0 40px;
  border: 1px solid #d9d9d9;
  position: relative;
  .item {
    padding: 40px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    .information {
      width: 900px;
      display: flex;
      .col {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        &:last-child {
          flex: 2;
        }
        .key {
          margin-bottom: 40px;
        }
      }
    }
    .button {
      display: flex;
      align-items: center;
      .edit {
        width: 200px;
        height: 48px;
        line-height: 48px;
        background: #123178;
        border-radius: 6px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        margin-right: 40px;
        cursor: pointer;
        &:hover {
      background-color: #021450;
    }
      }
      .del {
        font-size: 14px;
        font-weight: 600;
        color: #123178;
        cursor: pointer;
      }
    }
  }
}
.newAddress {
  width: 200px;
  height: 48px;
  line-height: 48px;
  background: #fff;
  border-radius: 6px;
  text-align: center;
  border: 1px solid #123178;
  font-size: 14px;
  font-weight: 600;
  color: #123178;
  margin: 40px 0;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .title {
    font-size: 4vw;
    line-height: 12vw;
  }
  .block {
    padding: 0 3vw;
    .item {
      padding: 3vw 0;
      flex-direction: column;
      align-items: flex-start;
      .information {
        width: 100%;
        flex-direction: column;
        .col {
          flex-direction: row;
          font-size: 3.5vw;
          .key {
            margin: 0 10vw 3vw 0;
          }
          .val {
            text-align: right;
            flex: 1;
          }
        }
      }
      .button {
        display: flex;
        align-items: center;
        margin-top: 5vw;
        .edit {
          width: 20vw;
          height: 9vw;
          line-height: 9.5vw;
          background: #123178;
          border-radius: 6px;
          text-align: center;
          color: #fff;
          font-size: 3.5vw;
          font-weight: 600;
          color: #ffffff;
          margin-right: 40px;
          cursor: pointer;
          &:hover {
      background-color: #021450;
    }
        }
        .del {
          font-size: 3.5vw;
          font-weight: 600;
          color: #123178;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
